<template>
  <div class="pro_action">
    <h2>{{ $t("L_PRODUCT.CONTENT_0013") }}</h2>
    <ul class="pro_action_main">
      <li v-for="(item, index) in actionList" :key="index">
        <img :src="item.imgURl" />
        <p>{{ item.title }}</p>
      </li>
    </ul>
    <div class="pro_action_btn">
      <a
        :class="btnClass"
        :href="link"
        target="_blank"
        v-if="btnClass !== 'no'"
        @click.prevent="linkClick(link)"
        >{{ btnText }}</a
      >
      <a :class="btnClass" :href="link" v-else @click="handleShowTip">{{
        btnText
      }}</a>
    </div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="400"
      :before-close="handleClose"
    >
      <p>{{ $t("L_MAIN.BUTTON_0006") }}</p>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">{{
          $t("L_SYSTEM_TIP.TIP_0002")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { loginByPhone, loginByName } from "@/api/building";
export default {
  name: "action",
  data() {
    return {
      actionList: [
        {
          imgURl: "",
          title: this.$t("L_PRODUCT.CONTENT_0014"),
        },
        {
          imgURl: "",
          title: this.$t("L_PRODUCT.CONTENT_0015"),
        },
        {
          imgURl: "",
          title: this.$t("L_PRODUCT.CONTENT_0016"),
        },
        {
          imgURl: "",
          title: this.$t("L_PRODUCT.CONTENT_0017"),
        },
        {
          imgURl: "",
          title: this.$t("L_PRODUCT.CONTENT_0018"),
        },
        {
          imgURl: "",
          title: this.$t("L_PRODUCT.CONTENT_0019"),
        },
      ],
      link: null,
      btnText: null,
      btnClass: "",
      dialogTitle: this.$t("L_SYSTEM_TIP.TIP_0001"),
      dialogButton: this.$t("L_SYSTEM_TIP.TIP_0002"),
      dialogVisible: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.handleRenderButton();
      this.renderImages();
    });
  },
  computed: {
    userInfo() {
      return this.$store.state.system.userInfo;
    },
    token() {
      return this.$store.state.system.token;
    },
    loginStatus() {
      return this.$store.state.system.isLogin;
    },
  },
  methods: {
    getStaticData(name) {
      return require(`@/assets/image/pc/product/insar/action/changj0${name}.png`);
    },
    renderImages() {
      this.actionList.forEach((item, index) => {
        let i = index + 1;
        item.imgURl = this.getStaticData(i);
      });
    },
    handleRenderButton() {
      // 获取城市建筑形变风险智能化排查系统token---start
      let building_token = null;
      let userLogin = window.localStorage.userLogin;
      if (userLogin && userLogin !== "" && userLogin !== null) {
        let obj = JSON.parse(userLogin);
        if (obj.phone) {
          // ---手机号登录
          loginByPhone(obj.phone).then((res) => {
            if (res.code === 200) {
              building_token = res.token;
            }
          });
        } else if (obj.username && obj.password) {
          // ---密码登录
          let param = {
            username: obj.username,
            password: obj.password,
          };
          loginByName(param).then((res) => {
            if (res.code === 200) {
              building_token = res.token;
            }
          });
        }
      }
      // 获取城市建筑形变风险智能化排查系统token---end
      setTimeout(() => {
        if (this.userInfo !== "" && this.userInfo.function) {
          let statusArr = this.userInfo.function.split(",");
          let status = statusArr[7];
          let activeLink = "";
          activeLink =
              "https://www.larkview.cn/insar/#/?token=" +
              this.token +
              "&type=login";
          if (building_token !== null) {
            activeLink = activeLink + "&buildingToken=" + building_token
          }
          if (status === "1") {
            this.link = activeLink;
            this.btnText = this.$t("L_PRODUCT.BUTTON_0002");
            this.btnClass = "use";
          } else {
            this.link = "javascript:;";
            this.btnText = this.$t("L_PRODUCT.BUTTON_0003");
            this.btnClass = "no";
          }
        } else {
          this.link = "#/login?type=2";
          this.btnText = this.$t("L_PRODUCT.BUTTON_0001");
          this.btnClass = "try";
        }
      }, 1000)
    },
    handleShowTip() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    linkClick(link) {
      if (!this.loginStatus) {
        this.$router.push({ path: "/login?type=2" });
      } else {
        if (window.localStorage.larkview_userInfo && JSON.parse(window.localStorage.larkview_userInfo).value !== "") {
          window.open(link);
        } else {
          this.$router.push({ path: "/error" });
          return;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/styles/pc/product/insar/action";
</style>
