<template>
  <div class="pro_func">
    <h2>{{ $t("L_PRODUCT.CONTENT_0002") }}</h2>
    <ul class="pro_func_main">
      <li v-for="(item, index) in funcList" :key="index">
        <img :src="item.imgURl" />
        <p>{{ item.title }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "func",
  data() {
    return {
      funcList: [
        {
          imgURl: "",
          title: this.$t("L_PRODUCT.CONTENT_0003_1"),
        },
        {
          imgURl: "",
          title: this.$t("L_PRODUCT.CONTENT_0003_2"),
        },
        {
          imgURl: "",
          title: this.$t("L_PRODUCT.CONTENT_0003_3"),
        },
        {
          imgURl: "",
          title: this.$t("L_PRODUCT.CONTENT_0003_4"),
        }
      ]
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.renderImages();
    })
  },
  methods: {
    getStaticData(name) {
      return require(`@/assets/image/pc/product/insar/func/tu${name}.jpg`);
    },
    renderImages() {
      this.funcList.forEach((item, index) => {
        let i = index + 1;
        item.imgURl = this.getStaticData(i);
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/styles/pc/product/insar/func";
</style>