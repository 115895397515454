<template>
  <div class="pro_banner">
    <div class="pro_banner_img" :style="{ height: height }">
      <div class="pro_banner_txt">
        <h2>{{ $t("L_PRODUCT.TITLE_0001") }}</h2>
        <p class="p1">{{ $t("L_PRODUCT.CONTENT_0001") }}</p>
        <p class="p2">
          <a
            :class="btnClass"
            :href="link"
            target="_blank"
            v-if="btnClass !== 'no'"
            @click.prevent="linkClick(link)"
            >{{ btnText }}</a
          >
          <a :class="btnClass" :href="link" v-else @click="handleShowTip">{{
            btnText
          }}</a>
        </p>
      </div>
    </div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="400"
      :before-close="handleClose"
    >
      <p>{{ $t("L_MAIN.BUTTON_0006") }}</p>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">{{
          $t("L_SYSTEM_TIP.TIP_0002")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { loginByPhone, loginByName } from "@/api/building";
export default {
  name: "banner",
  data() {
    return {
      height: 0,
      link: null,
      btnText: null,
      btnClass: "",
      dialogTitle: this.$t("L_SYSTEM_TIP.TIP_0001"),
      dialogButton: this.$t("L_SYSTEM_TIP.TIP_0002"),
      dialogVisible: false,
    };
  },
  mounted() {
    this.height = this.$getViewportSize().height + "px";
    this.$nextTick(() => {
      this.handleRenderButton();
    });
    window.onresize = () => {
      return (() => {
        this.height = this.$getViewportSize().height + "px";
      })();
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.system.userInfo;
    },
    token() {
      return this.$store.state.system.token;
    },
    loginStatus() {
      return this.$store.state.system.isLogin;
    },
  },
  methods: {
    handleRenderButton() {
      // 获取城市建筑形变风险智能化排查系统token---start
      let building_token = null;
      let userLogin = window.localStorage.userLogin;
      if (userLogin && userLogin !== "" && userLogin !== null) {
        let obj = JSON.parse(userLogin);
        if (obj.phone) {
          // ---手机号登录
          loginByPhone(obj.phone).then((res) => {
            if (res.code === 200) {
              building_token = res.token;
            }
          });
        } else if (obj.username && obj.password) {
          // ---密码登录
          let param = {
            username: obj.username,
            password: obj.password,
          };
          loginByName(param).then((res) => {
            if (res.code === 200) {
              building_token = res.token;
            }
          });
        }
      }
      // 获取城市建筑形变风险智能化排查系统token---end
      setTimeout(() => {
        if (this.userInfo !== "" && this.userInfo.function) {
          let statusArr = this.userInfo.function.split(",");
          let status = statusArr[7];
          let activeLink = "";
          activeLink =
              "https://www.larkview.cn/insar/#/?token=" +
              this.token +
              "&type=login";
          if (building_token !== null) {
            activeLink = activeLink + "&buildingToken=" + building_token
          }
          if (status === "1") {
            this.link = activeLink;
            this.btnText = this.$t("L_PRODUCT.BUTTON_0002");
            this.btnClass = "use";
          } else {
            this.link = "javascript:;";
            this.btnText = this.$t("L_PRODUCT.BUTTON_0003");
            this.btnClass = "no";
          }
        } else {
          this.link = "#/login?type=2";
          this.btnText = this.$t("L_PRODUCT.BUTTON_0001");
          this.btnClass = "try";
        }
      }, 1000)
    },
    handleShowTip() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    linkClick(link) {
      if (!this.loginStatus) {
        this.$router.push({ path: "/login?type=2" });
      } else {
        if (window.localStorage.larkview_userInfo && JSON.parse(window.localStorage.larkview_userInfo).value !== "") {
          window.open(link);
        } else {
          this.$router.push({ path: "/error" });
          return;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/styles/pc/product/insar/banner.scss";
</style>
